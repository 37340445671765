import styled from 'styled-components';
import { AdvancedImage } from '@cloudinary/react';
import { breakpoints, radius, spacing } from '@naf/theme';
import { Link } from 'react-router-dom';
import { Text } from '@naf/text';
import { Label } from '@naf/label';
import { nafColor } from '@nafcore/theme';

export const StyledAdvancedImage = styled(AdvancedImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

export const Wrapper = styled(Link)<{ $isFlexible?: boolean }>`
  width: ${({ $isFlexible }) => ($isFlexible ? 'clamp(205px, 30vw, 353px)' : '353px')};
  height: 414px;
  display: flex;
  flex-direction: column;
  border-radius: ${radius.s};
  border: 1px solid #c1c1c1;
  background: #fff;
  text-decoration: none;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  position: relative;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 ${spacing.space4} ${spacing.space8} ${nafColor.signature.black}33;
  }

  &:hover ${StyledAdvancedImage} {
    transform: scale(1.05);
  }

  @media (max-width: ${breakpoints.m}) {
    height: auto;
    width: 100%;
    box-shadow: 0 ${spacing.space4} ${spacing.space8} ${nafColor.signature.black}33;

    &:hover ${StyledAdvancedImage} {
      transform: none;
    }
  }
`;

export const Header = styled.div<{ $isFlexible?: boolean }>`
  width: ${({ $isFlexible }) => ($isFlexible ? '100%' : '353px')};
  height: ${({ $isFlexible }) => ($isFlexible ? 'clamp(120px, 15vw, 190px)' : '190px')};
  display: flex;
  overflow: hidden;
  position: relative;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;

  @media (max-width: ${breakpoints.m}) {
    height: auto;
    width: 100%;
  }
`;

export const Description = styled.div`
  padding: 0 ${spacing.space24} ${spacing.space24} ${spacing.space24};
  flex: 1;
`;

export const Title = styled(Text)`
  margin: 0;
  margin-top: ${spacing.space4};
`;

export const Ingress = styled(Text)<{ $limitIngress?: boolean }>`
  margin: 0;
  ${({ $limitIngress }) =>
    $limitIngress
      ? `
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `
      : null}
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${spacing.space8};
  margin-right: ${spacing.space8};
  height: ${spacing.space40};
`;

export const PartnerLogo = styled.img`
  max-width: 90px;
  max-height: ${spacing.space40};
`;

export const LogoPlaceholder = styled.div`
  width: 90px;
  height: ${spacing.space40};
  visibility: hidden;
`;

export const Discount = styled(Text)`
  margin: 0;
  padding: 0 ${spacing.space24} ${spacing.space24} ${spacing.space24};
`;

export const Campaign = styled(Label)`
  position: absolute;
  top: -12px;
  left: 18px;
  z-index: 2;
`;
